@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

@font-face {
    font-family: 'Tan Nimbus';
    src: url('../font/TAN-NIMBUS.otf');
}

.nimbus {
    font-family: 'Tan Nimbus';
}

.spartan {
    font-family: "League Spartan";
}

:root {
    --primary-color: #0B60B0;
    --secondary-color: #FEDDBE;
    --bg-color: #000000;
    --supporting-color: #FFC947;
    --white-color: #fff;
    --league-spartan: "League Spartan";
    --section-y-spacing: 4rem;
    --section-x-spacing: 1rem;
    --icons-size: 2rem;
    --section-title: 3rem;
    --section-subtitle: 2rem;
    --section-title-weight: 600;
}

@media screen and (max-width: 475px) {
    :root {
        --section-y-spacing: 1rem;
    }
}

body {
    font-family: 'Poppins', sans-serif !important;
    background-color: var(--bg-color) !important;
    /* cursor: none; */
}

button a {
    text-decoration: none;
    color: inherit;
}

.custom-btn {
    border-radius: 1.5rem !important;
    padding: 0.5rem 2rem !important;
    /* background-image: linear-gradient(to right, var(--primary-color), var(--supporting-color)); */
    background: var(--supporting-color);
    color: var(--bg-color) !important;
    border: none !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
    -webkit-transition: 500ms ease-in-out !important;
    transition: 500ms ease-in-out !important;
}

/* .custom-btn::after {
    content: "\2192";
    display: none;
} */

.custom-btn:hover {
    color: var(--secondary-color) !important;
    background-color: var(--primary-color) !important;
    border-color: var(--secondary-color) !important;
    /* -webkit-transition: 1000ms ease-in-out !important; */
    /* transition: 1000ms ease-in-out !important; */
    /* &::after {
        display: inline-block;
        margin-left: 1rem;
    } */
}

.custom-black-btn, .custom-black-btn:hover {
    background-color: #000000 !important;
    color: var(--white-color) !important;
}

.custom-outline-btn {
    border-radius: 1.5rem !important;
    padding: 0.5rem 2rem !important;
    background-color: var(--supporting-color) !important;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
}

.custom-outline-btn::after {
    content: "\2192";
    display: none;
}

.custom-outline-btn:hover {
    background-color: var(--primary-color) !important;
    color: var(--secondary-color) !important;
    -webkit-transition: 1000ms ease-in-out !important;
    transition: 1000ms ease-in-out !important;
    &::after {
        display: inline-block;
        margin-left: 1rem;
    }
}

.gradient {
    background-image: linear-gradient(to right, var(--primary-color), var(--supporting-color));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.section-title {
    font-family: var(--league-spartan);
    font-size: var(--section-title);
    font-weight: var(--section-title-weight);
}

section {
    padding-top: var(--section-y-spacing);
    background-color: var(--bg-color) !important;
}

@media screen and (max-width: 475px) {
    section {
        padding: var(--section-y-spacing) var(--section-x-spacing);
    }
}

#custom-cursor {
    position: fixed;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    left: -100px;
    top: 50%;
    mix-blend-mode: difference; 
    background: white;
    z-index: 10000;
    height: 30px;
    width: 30px;
    transition: all 0ms ease-out;
}