.pricing-title {
    font-size: var(--section-title);
}
.pricing-table {
    padding: 1rem 2rem;
    border-radius: 1rem;
}
.pricing-table:nth-child(1) {
    background: white;
    color: black;
}
.pricing-table:nth-child(2) {
    background: #0B60B0;
    color: white;
}
.pricing-table:nth-child(3) {
    background: #FFC947;
    color: black;
}